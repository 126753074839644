import canUseDOM from '../utils/canUseDom'
import toTitleCase from '../utils/formatting/toTitleCase'
import {
  propertyType as propertyTypes,
  propertyType,
} from '../constants/propertyType'

const isMixpanelAvailable = () => {
  return typeof window.mixpanel !== 'undefined'
}
const shouldLogEventsToConsole = () =>
  canUseDOM && window.location.hostname !== 'www.accommodationforstudents.com'

const initialPropertyDetailsEventProperties = {
  'Property Id': undefined,
  'Property Type': undefined,
  'Price Per Week': undefined,
  'Bills Included': undefined,
  'Total Number Of Bedrooms': undefined,
  'Number Of Available Bedrooms': undefined,
  'Number Of Images': undefined,
  'Virtual Tour': undefined,
  'Video Tour': undefined,
  'Special Offers': undefined,
  'Is Enhanced': undefined,
  'Number Of Special Offers': undefined,
  'Has Rooms': undefined,
  'Accepts Bookings': undefined,
  'Address 1': undefined,
  'Address 2': undefined,
  Area: undefined,
  City: undefined,
  Postcode: undefined,
  'Account Type': undefined,
  'Accommodation Provider': undefined,
  Campaign: undefined,
  'Property Alert Id': undefined,
  'From AFS Support': undefined,
  'Enquiry Type': undefined,
}

const initialPropertyCardEventProperties = {
  'Property Id': undefined,
  'Listing Position': undefined,
  'Property Type': undefined,
  'Development Name': undefined,
  'Accommodation Provider': undefined,
  'Number Of Images': undefined,
  'Price Per Week': undefined,
  'Bills Included': undefined,
  'Total Number Of Bedrooms': undefined,
  'Number Of Available Bedrooms': undefined,
  'Is For Groups Only': undefined,
  'Number Of Bathrooms': undefined,
  'Virtual Tour': undefined,
  'Video Tour': undefined,
  'Special Offers': undefined,
  'Number Of Special Offers': undefined,
  'Address 1': undefined,
  'Address 2': undefined,
  Area: undefined,
  City: undefined,
  Postcode: undefined,
  Latitude: undefined,
  Longitude: undefined,
  'Accepts Bookings': undefined,
  'Is PBSA Property': undefined,
  'Is Sold Out': undefined,
  'Academic Year': undefined,
  'Has Room Options': undefined,
  'Number Of Room Options': undefined,
  'Last Boosted Date': undefined,
  'Total Number Of Results': undefined,
  'Clicked From': undefined,
}

export const logEvent = (eventName, eventProperties) => {
  if (shouldLogEventsToConsole()) {
    console.log(eventProperties, eventName)
  }

  if (isMixpanelAvailable()) {
    mixpanel.track(eventName, eventProperties)
  }
}

export const logPropertyDetailsEvent = (eventName, eventProperties) => {
  logEvent(eventName, {
    ...initialPropertyDetailsEventProperties,
    ...eventProperties,
  })
}

export const eventPropertiesFromPropertyDetails = (propertyDetails) => {
  const getAccountType = () => {
    if (
      propertyDetails.roomName ||
      propertyDetails.landlord?.providerType === 'Student hall'
    ) {
      return 'PBSA'
    }

    return toTitleCase(
      propertyDetails.landlord?.providerType ||
        propertyDetails.accommodationProvider?.providerType
    )
  }

  return {
    'Property Id': propertyDetails.id,
    'Property Type': propertyDetails.propertyType,
    'Price Per Week': propertyDetails.price?.rentPerWeekFrom,
    'Bills Included': propertyDetails.bills?.whichBillsAreIncluded,
    'Total Number Of Bedrooms': propertyDetails.occupancy.total,
    'Number Of Available Bedrooms': propertyDetails.occupancy.available,
    'Number Of Images': propertyDetails.images.length,
    'Virtual Tour': propertyDetails.hasVirtualTour,
    'Video Tour': propertyDetails.hasVideo,
    'Special Offers': propertyDetails.hasSpecialOffers || false,
    'Number Of Special Offers': propertyDetails.hasSpecialOffers
      ? propertyDetails.specialOffers.length
      : 0,
    'Address 1': propertyDetails.address.address1,
    'Address 2': propertyDetails.address.address2,
    Area: propertyDetails.address.area,
    City: propertyDetails.address.city,
    Postcode: propertyDetails.address.postcode,
    'Accepts Bookings': propertyDetails.acceptsBookings,
    'Account Type': getAccountType(),
    'Accommodation Provider':
      propertyDetails.accommodationProvider?.companyName,
  }
}

export const eventPropertiesFromStudentHallDetails = (propertyDetails) => {
  const hasSpecialOffers = !!(
    propertyDetails.enhanced && propertyDetails.hasSpecialOffers
  )

  return {
    'Property Id': propertyDetails.id,
    'Property Type': 'halls',
    'Price Per Week': propertyDetails.price.rentPerWeekFrom,
    'Bills Included': propertyDetails.bills.whichBillsAreIncluded,
    'Total Number Of Bedrooms': 1,
    'Number Of Available Bedrooms': 1,
    'Number Of Images': propertyDetails.images.length,
    'Virtual Tour': propertyDetails.hasVirtualTour,
    'Video Tour': propertyDetails.hasVideo,
    'Special Offers': hasSpecialOffers,
    'Number Of Special Offers': hasSpecialOffers
      ? propertyDetails.specialOffers?.length
      : 0,
    'Is Enhanced': propertyDetails.enhanced,
    'Has Rooms': propertyDetails.hasRooms,
    'Address 1': propertyDetails.address.address1,
    'Address 2': propertyDetails.address.address2,
    Area: propertyDetails.address.area,
    City: propertyDetails.address.city,
    Postcode: propertyDetails.address.postcode,
    'Accepts Bookings': propertyDetails.acceptsBookings,
    'Account Type': 'PBSA',
    'Accommodation Provider': propertyDetails.accommodationProvider.companyName,
  }
}

const propertyCardEventPropertiesFrom = (property) => {
  const developmentName = () => {
    return property.propertyType === propertyType.halls
      ? property.address.address1
      : undefined
  }

  const numberOfImages = () => {
    return property.images[0]?.url.includes('no-photos')
      ? 0
      : property.images.length
  }

  const bedroomsTotal = () => {
    if (property.propertyType === propertyType.halls) return 1
    return property.occupancy.total
  }

  const bedroomsAvailable = () => {
    if (property.propertyType === propertyType.halls) return 1
    return property.occupancy.available
  }

  return {
    ...initialPropertyCardEventProperties,
    'Property Id': property.id,
    'Property Type': property.propertyType,
    'Development Name': developmentName(),
    'Accommodation Provider': property.accommodationProvider?.name,
    'Number Of Images': numberOfImages(),
    'Price Per Week': property.terms.rentPpw.value,
    'Bills Included': property.terms.billsIncluded,
    'Total Number Of Bedrooms': bedroomsTotal(),
    'Number Of Available Bedrooms': bedroomsAvailable(),
    'Is For Groups Only': property.isForGroupsOnly,
    'Number Of Bathrooms': property.numberOfBathrooms,
    'Virtual Tour': property.hasVirtualTour,
    'Video Tour': property.hasVideo,
    'Special Offers': property.hasSpecialOffers,
    'Number Of Special Offers': property.numberOfSpecialOffers,
    'Address 1': property.address.address1,
    'Address 2': property.address.address2,
    Area: property.address.area,
    City: property.address.city,
    Postcode: property.address.postcode,
    Latitude: property.coordinates?.lat,
    Longitude: property.coordinates?.lon,
    'Accepts Bookings': property.acceptsBookings,
    'Is PBSA Property': property.isPbsaProperty,
    'Is Sold Out': property.isSoldOut,
    'Academic Year': property.academicYearLabel,
    'Has Room Options': property.hasRoomOptions,
    'Number Of Room Options': property.numberOfRoomOptionsAvailable,
    'Last Boosted Date': property.lastBoosted,
  }
}

const mapListingEventPropertiesFrom = (listing, totalNumberOfListings) => {
  return {
    ...initialPropertyCardEventProperties,
    'Property Id': listing.id,
    'Property Type': listing.propertyType,
    'Development Name':
      listing.propertyType === propertyType.halls
        ? listing.address.address1
        : undefined,
    'Accommodation Provider': listing.accommodationProvider?.name,
    'Price Per Week': listing.terms.rentPpw.value,
    'Total Number Of Bedrooms': listing.occupancy ? listing.occupancy.total : 1,
    'Number Of Available Bedrooms': listing.occupancy
      ? listing.occupancy.available
      : 1,
    'Is For Groups Only': listing.isForGroupsOnly,
    'Accepts Bookings': listing.acceptsBookings,
    'Has Room Options': listing.hasRoomOptions,
    'Number Of Room Options': listing.hasRoomOptions
      ? listing.numberOfRoomOptionsAvailable
      : undefined,
    'Is Sold Out': listing.isSoldOut,
    Latitude: listing.coordinates.lat,
    Longitude: listing.coordinates.lon,
    'Total Number Of Results': totalNumberOfListings,
  }
}

export const logViewPropertyEventOnAccommodationDetailsPage = (
  propertyDetails,
  propertyAlertId,
  campaign,
  isPbsaProperty
) => {
  logPropertyDetailsEvent('View Property', {
    ...eventPropertiesFromPropertyDetails(propertyDetails),
    'Is Available': propertyDetails.availability.isAvailable,
    'Is PBSA Property': isPbsaProperty,
    Campaign: campaign || undefined,
    'Property Alert Id': propertyAlertId || undefined,
  })
}

export const logViewPropertyEventOnStudentHallDetailsPage = (
  propertyDetails,
  propertyAlertId,
  campaign
) => {
  logPropertyDetailsEvent('View Property', {
    ...eventPropertiesFromStudentHallDetails(propertyDetails),
    'Is Available': propertyDetails.available,
    Campaign: campaign || undefined,
    'Property Alert Id': propertyAlertId || undefined,
  })
}

export const logListingClickEvent = (eventProperties) => {
  logEvent('Click Listing', eventProperties)
}

const logPropertyGridListingClickEvent = (
  listing,
  listingPosition,
  totalNumberOfResults,
  clickedFrom
) => {
  logListingClickEvent({
    ...propertyCardEventPropertiesFrom(listing.property),
    'Listing Position': listingPosition,
    'Total Number Of Results': totalNumberOfResults,
    'Clicked From': clickedFrom,
  })
}

export const logMapListingClickEvent = (listing, totalNumberOfListings) => {
  logListingClickEvent({
    ...mapListingEventPropertiesFrom(listing, totalNumberOfListings),
    'Clicked From': 'Search Results Page Map',
  })
}

export const logMapMarkerClickEvent = (listing, totalNumberOfListings) => {
  logEvent(
    'Click Marker On Map',
    mapListingEventPropertiesFrom(listing, totalNumberOfListings)
  )
}

export const logPropertyGridListingClickEventOnSearchPage = (
  listing,
  listingPosition,
  totalNumberOfResults
) => {
  logPropertyGridListingClickEvent(
    listing,
    listingPosition,
    totalNumberOfResults,
    'Search Results Page Grid'
  )
}

export const logPropertyGridListingClickEventOnLocationLandingPage = (
  listing,
  listingPosition,
  totalNumberOfResults
) => {
  logPropertyGridListingClickEvent(
    listing,
    listingPosition,
    totalNumberOfResults,
    'Location Landing Page Grid'
  )
}

export const logPropertyTypeSectionListingClickEventOnLocationLandingPage = (
  listing,
  listingPosition,
  totalNumberOfResults
) => {
  logPropertyGridListingClickEvent(
    listing,
    listingPosition,
    totalNumberOfResults,
    'Location Landing Page Property Type Section'
  )
}

export const logPropertyTypeSectionLinkClickEventOnLocationLandingPage = (
  propertyType,
  linkType,
  location
) => {
  logEvent('Click Link In Property Type Section', {
    'Property Type': propertyType,
    'Link Type': linkType,
    Location: location,
  })
}

const logPropertyCardClickEventOnEnhancedPbsaProfilePropertyGrid = (
  listing,
  clickedFrom
) => {
  logListingClickEvent({
    ...propertyCardEventPropertiesFrom(listing),
    'Clicked From': clickedFrom,
  })
}

export const logPropertyCardClickEventOnPrivateHallSectionOnPropertyDetailsPage =
  (listing) => {
    logPropertyCardClickEventOnEnhancedPbsaProfilePropertyGrid(
      listing,
      'PBSA Profile Section On Property Details Page'
    )
  }

export const logPropertyCardClickEventOnPrivateHallProfilePagePropertyGrid = (
  listing
) => {
  logPropertyCardClickEventOnEnhancedPbsaProfilePropertyGrid(
    listing,
    'PBSA Profile Page'
  )
}

const logChangeSelectedPageInPagination = (
  selectedPage,
  totalNumberOfPages,
  clickedFrom
) => {
  logEvent('Change Selected Page In Pagination', {
    'Selected Page': selectedPage,
    'Total Number Of Pages': totalNumberOfPages,
    'Clicked From': clickedFrom,
  })
}

export const logChangeSelectedPageInPaginationOnSearchResultsPage = (
  selectedPage,
  totalNumberOfPages
) => {
  logChangeSelectedPageInPagination(
    selectedPage,
    totalNumberOfPages,
    'Search Results Page'
  )
}

export const logChangeSelectedPageInPaginationOnLocationLandingPage = (
  selectedPage,
  totalNumberOfPages
) => {
  logChangeSelectedPageInPagination(
    selectedPage,
    totalNumberOfPages,
    'Location Landing Page'
  )
}

export const logAdvertisePropertyCardClickEventOnLocationLandingPage = (
  location,
  totalNumberOfResults
) => {
  logEvent('Click Advertise Property Card', {
    Location: location,
    'Total Number Of Results': totalNumberOfResults,
    'Clicked From': 'Location Landing Page Grid',
  })
}

export const logAdvertiseYourPropertyBannerClickEventOnLocationLandingPage = (
  location
) => {
  logEvent('Click Advertise Your Property Banner', {
    Location: location,
    'Clicked From': 'Location Landing Page',
  })
}

export const logAdvertisePropertyCardClickInPropertyTypeSectionEventOnLocationLandingPage =
  (location, totalNumberOfResults, propertyType) => {
    logEvent('Click Advertise Property Card', {
      Location: location,
      'Total Number Of Results': totalNumberOfResults,
      'Property Type': propertyType,
      'Clicked From': 'Location Landing Page Property Type Section',
    })
  }

export const logExternalLinkClickOnStudentHallDetailsPage = (
  eventProperties
) => {
  logEvent('Click PBSA External Link', eventProperties)
}

export const logSocialShareLinkClickOnPropertyDetailsPage = (
  shareLinkName,
  listing,
  isPbsaProperty
) => {
  const propertyDetails =
    listing.propertyType === undefined ||
    listing.propertyType === propertyTypes.halls
      ? eventPropertiesFromStudentHallDetails(listing)
      : eventPropertiesFromPropertyDetails(listing)

  const eventProperties = {
    ...propertyDetails,
    'Is PBSA Property': isPbsaProperty,
    'Social Share Link Name': shareLinkName,
  }

  logEvent('Click Social Share Link On Property Details Page', eventProperties)
}

export const logViewImageInPropertyCardCarousel = (
  imagePosition,
  totalNumberOfImages,
  carouselSlideDirection,
  propertyId,
  propertyType,
  carouselAbTestVariant
) => {
  logEvent('View Image In Property Card Carousel', {
    'Image Position': imagePosition,
    'Total Number Of Images': totalNumberOfImages,
    'Carousel Slide Direction': carouselSlideDirection,
    'Property Id': propertyId,
    'Property Type': propertyType,
    Variant: carouselAbTestVariant,
  })
}

export const logViewPropertyInPropertyCarousel = (
  slidePosition,
  totalNumberOfSlides,
  carouselSlideDirection,
  propertyId,
  propertyType
) => {
  logEvent('View Property In Property Carousel', {
    'Slide Position': slidePosition,
    'Total Number Of Slides': totalNumberOfSlides,
    'Carousel Slide Direction': carouselSlideDirection,
    'Property Id': propertyId,
    'Property Type': propertyType,
  })
}
