import PropertyType from '../types/PropertyType'

export const propertyType = {
  any: 'any',
  house: 'house',
  flat: 'flat',
  studio: 'studio',
  halls: 'halls',
} as const

export const pluralisedPropertyTypeFrom = (type: PropertyType) => {
  return type === propertyType.halls ? type : `${type}s`
}
